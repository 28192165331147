import React from 'react'
import { Link } from 'gatsby'

const Welcome = () => {
  return (
    <div className="background-colour-beige-1">
      <div className="container p-5 text-center">
        <h2>
          From the moment you enter our space, we want you to feel welcomed and
          at home.
        </h2>
        <h4>
          From bold and bright to soft and subtle, what really matters to us is
          that you are listened to properly and your hair reflects you. Let's
          achieve your ultimate hair goals from our boutique home salon in
          Labrador on the Gold Coast
        </h4>
        <Link className="links" to="/about">
          A Little About Us
        </Link>
      </div>
    </div>
  )
}

export default Welcome
